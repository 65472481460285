define("discourse/plugins/discourse-gamification/discourse/components/minimal-gamification-leaderboard", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="leaderboard -minimal">
    <div class="page__header">
      <LinkTo
        @route="gamificationLeaderboard.byName"
        @model={{this.model.leaderboard.id}}
      >
        <h3 class="page__title">{{this.model.leaderboard.name}}</h3>
      </LinkTo>
    </div>
  
    <div class="ranking-col-names">
      <span>{{i18n "gamification.leaderboard.rank"}}</span>
      <span>{{d-icon "award"}}{{i18n "gamification.score"}}</span>
    </div>
    <div class="ranking-col-names__sticky-border"></div>
    {{#if (and this.currentUserRanking.user this.notTopTen)}}
      <div class="user -self">
        <div class="user__rank">{{this.currentUserRanking.position}}</div>
        <div class="user__name">{{i18n "gamification.you"}}</div>
        <div class="user__score">
          {{#if this.site.mobileView}}
            {{number this.currentUserRanking.user.total_score}}
          {{else}}
            {{fullnumber this.currentUserRanking.user.total_score}}
          {{/if}}
        </div>
      </div>
    {{/if}}
  
    {{#each this.ranking as |rank index|}}
      <MinimalGamificationLeaderboardRow @rank={{rank}} @index={{index}} />
    {{/each}}
  </div>
  */
  {
    "id": "VLb4K4W4",
    "block": "[[[10,0],[14,0,\"leaderboard -minimal\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page__header\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"gamificationLeaderboard.byName\",[30,0,[\"model\",\"leaderboard\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"h3\"],[14,0,\"page__title\"],[12],[1,[30,0,[\"model\",\"leaderboard\",\"name\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"ranking-col-names\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,1],[\"gamification.leaderboard.rank\"],null]],[13],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,2],[\"award\"],null]],[1,[28,[35,1],[\"gamification.score\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ranking-col-names__sticky-border\"],[12],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"currentUserRanking\",\"user\"]],[30,0,[\"notTopTen\"]]],null],[[[1,\"    \"],[10,0],[14,0,\"user -self\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"user__rank\"],[12],[1,[30,0,[\"currentUserRanking\",\"position\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"user__name\"],[12],[1,[28,[35,1],[\"gamification.you\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"user__score\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[1,[28,[35,5],[[30,0,[\"currentUserRanking\",\"user\",\"total_score\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,6],[[30,0,[\"currentUserRanking\",\"user\",\"total_score\"]]],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"ranking\"]]],null]],null],null,[[[1,\"    \"],[8,[39,9],null,[[\"@rank\",\"@index\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1,2]],null],[13]],[\"rank\",\"index\"],false,[\"link-to\",\"i18n\",\"d-icon\",\"if\",\"and\",\"number\",\"fullnumber\",\"each\",\"-track-array\",\"minimal-gamification-leaderboard-row\"]]",
    "moduleName": "discourse/plugins/discourse-gamification/discourse/components/minimal-gamification-leaderboard.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "notTop10", [_tracking.tracked], function () {
      return true;
    }))();
    #notTop10 = (() => (dt7948.i(this, "notTop10"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked], function () {
      return null;
    }))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    constructor() {
      super(...arguments);
      const count = this.args?.params?.count || 10;
      const data = {
        user_limit: count
      };

      // used in the right sidebar blocks theme component
      const leaderboardId = this.args?.params?.id || null;
      const endpoint = leaderboardId ? `/leaderboard/${leaderboardId}` : "/leaderboard";
      (0, _ajax.ajax)(endpoint, {
        data
      }).then(model => {
        this.model = model;
      });
    }
    get currentUserRanking() {
      const user = this.model?.personal;
      if (user) {
        this.notTop10 = user.position > 10;
      }
      return user || null;
    }
    get ranking() {
      this.model?.users?.forEach(user => {
        if (user.id === this.model.personal?.user?.id) {
          user.isCurrentUser = "true";
        }
        if (this.model.users.indexOf(user) === 0) {
          user.topRanked = true;
        }
      });
      return this.model?.users;
    }
  });
});